import { ILivePollSession } from '../../interfaces/livepoll-session.interface';
import {
  dateStringToFormattedDate,
  secondsToHms,
} from '../../utils/DateTimeUtil';
import styles from './LpResponseCountAndTime.module.css';
import { TimerSvg } from '../svgImages/Timer.svg';
import { BackButtonSvg } from '../svgImages/BackButton.svg';
import { LpCopyReportUrl } from '../copy-report-url/LpCopyReportUrl';
import { WuButton } from '@npm-questionpro/wick-ui-lib';
import { LivePollType } from '../../enums/livepoll-type.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faGraduationCap,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { UserIconSvg } from '../svgImages/UserIcon.svg';
import { useState } from 'react';

interface Props {
  livePollSession: ILivePollSession;
  responseCount: number;
  questionCount: number;
  onBackButtonClick?(): void;
  reportUrl?: string;
  downloadRawSessionReport?: (livePollSessionId: number) => Promise<void>;
  isReportsApp?: boolean;
  text: {
    quiz: string;
    poll: string;
    duration: string;
    question: string;
    questions: string;
    participant: string;
    participants: string;
    downloadCsv?: string;
  };
}

export const LpResponseCountAndTime = (props: Props) => {
  const {
    livePollSession,
    responseCount,
    onBackButtonClick,
    reportUrl,
    questionCount,
    text,
    downloadRawSessionReport,
    isReportsApp,
  } = props;
  const [disabled, setDisabled] = useState<boolean>(false);
  const timeTaken = calculateTimeTaken(livePollSession);
  const sessionName = livePollSession.name || 'Untitled';
  const quizText = text.quiz;
  const pollText = text.poll;

  const backButtonClick = () => {
    onBackButtonClick && onBackButtonClick();
  };

  const handleDownloadReport = async () => {
    if (downloadRawSessionReport) {
      setDisabled(true);
      const livePollSessionId = livePollSession.id;
      await downloadRawSessionReport(livePollSessionId);
      setDisabled(false);
    }
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.sessionNameRow}>
        <div className={styles.sessionName}>
          {!isReportsApp && onBackButtonClick && (
            <WuButton
              variant="iconOnly"
              icon={
                <span data-testid="backIcon">
                  <BackButtonSvg />
                </span>
              }
              onClick={backButtonClick}
              className={styles.backButton}
            />
          )}
          {sessionName}
        </div>
        <div className={styles.copyReportRow}>
          {!isReportsApp && (
            <WuButton
              disabled={disabled}
              variant="secondary"
              onClick={handleDownloadReport}
              icon={<span className="wm-download"></span>}
              className={styles.shareIconContainer}
            >
              <span className={styles.downloadCsvText}>{text.downloadCsv}</span>
            </WuButton>
          )}

          <LpCopyReportUrl reportUrl={reportUrl} />
        </div>
      </div>

      <div className={styles.sessionDetailsRow}>
        <div className={styles.livePollTypeText}>
          <span className={styles.iconSpan}>
            <FontAwesomeIcon
              icon={
                livePollSession.livePollType === LivePollType.POLL
                  ? faChartLine
                  : faGraduationCap
              }
              size="lg"
              className={styles.livePollType}
            />
          </span>

          {livePollSession.livePollType === LivePollType.QUIZ
            ? quizText
            : pollText}
        </div>
        <div className={styles.createdDate}>
          <span className={`text.xl wm-calendar-month ${styles.iconSpan}`} />
          {dateStringToFormattedDate(livePollSession.createdAt)}
        </div>
        <div className={styles.responseCountContainer}>
          <span className={styles.iconSpan}>
            <UserIconSvg />
          </span>
          <span className={styles.responseCount} data-testid="response-count">
            {responseCount}
          </span>
          <span className={styles.participantText}>
            {responseCount === 1 ? text.participant : text.participants}
          </span>
        </div>
        <div className={styles.questionCount}>
          <span className={styles.iconSpan}>
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className={styles.questionIcon}
            />
          </span>

          <span>{questionCount}</span>
          <span className={styles.questionText}>
            {questionCount !== 1 ? text.questions : text.question}
          </span>
        </div>
        {timeTaken && (
          <div className={styles.sessionTimeContainer}>
            <span className={styles.iconSpan}>
              <TimerSvg width={16} height={16} />
            </span>
            <span className={styles.sessionTime} data-testid="time taken">
              {timeTaken}
            </span>
            <span className={styles.sessionTimePostfix}>{text.duration}</span>
          </div>
        )}
      </div>
    </div>
  );
};

function calculateTimeTaken(session: ILivePollSession): string | null {
  if (!session.completedAt) {
    return null;
  }

  const startTime = new Date(session.createdAt).getTime();
  const endTime = new Date(session.completedAt).getTime();
  const diffInSeconds = (endTime - startTime) / 1000;

  return secondsToHms(diffInSeconds);
}
