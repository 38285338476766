import styles from './LpQuestionTitle.module.css';
import { CSSProperties, useState } from 'react';
import { LpImagePopup } from '../image-popup/LpImagePopup';
import maximize from '../../images/maximize.svg';
import { LpDifficultyLevelIndicator } from '../difficulty-level-indicator/LpDifficultyLevelIndicator';
import { QuestionDifficultyLevel } from 'src/enums/question-difficulty-level.enum';

interface Props {
  currentQuestionNumber: number;
  title?: string;
  image?: { url: string };
  showDifficultyLevels?: boolean;
  difficultyLevel?: QuestionDifficultyLevel;
  difficultyLevelIndicatorStyle?: CSSProperties;
  type: string;
  icon?: React.ReactNode;
  text?: {
    easy: string;
    normal: string;
    hard: string;
  };
}

export const LpQuestionTitle = ({
  currentQuestionNumber,
  title,
  image,
  type,
  icon,
  showDifficultyLevels,
  difficultyLevel,
  difficultyLevelIndicatorStyle,
  text,
}: Props) => {
  const [displayImagePopup, setDisplayImagePopup] = useState<boolean>(false);
  const closePopup = () => {
    setDisplayImagePopup(false);
  };

  const hasImage = !!(image && image.url.length > 0);

  return (
    <div className={styles.container}>
      <div className={styles.questionTypeText}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div>{type}</div>
      </div>
      <div className={styles.questionDetails}>
        {hasImage && (
          <div
            onClick={() => setDisplayImagePopup(true)}
            className={styles.imageContainer}
          >
            <img src={image.url} alt="question pic" className={styles.image} />
            <div className={styles.imageOverlay}>
              <img
                src={maximize}
                alt="maximize question pic"
                width={14}
                className={styles.maximize}
              />
            </div>
          </div>
        )}
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <span
              className={styles.currentQuestionNumber}
              data-testid="questionNumber"
            >
              {currentQuestionNumber}.
            </span>{' '}
            <span data-testid="questionTitle">{title || 'Untitled'}</span>
          </div>
          {showDifficultyLevels && (
            <div className={styles.difficultyLevelIndicatorContainer}>
              <LpDifficultyLevelIndicator
                questionDifficultyLevel={difficultyLevel!}
                text={text!}
                difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
              />
            </div>
          )}
        </div>
      </div>

      {displayImagePopup && (
        <LpImagePopup image={image} closeImagePopup={closePopup} />
      )}
    </div>
  );
};
