import { LpLeaderboard } from '@livepolls/ui-components/src/components/leaderboard/LpLeaderboard';
import { LpProfileQuestionReports } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpProfileQuestionReports';
import { LpPanelContainer } from '@livepolls/ui-components/src/components/panel/LpPanelContainer';
import { LpPanelHeading } from '@livepolls/ui-components/src/components/panel/LpPanelHeading';
import { DashboardPanelType } from '@livepolls/ui-components/src/enums/dashboard-panel-type.enum';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { ILivePollSessionReport } from '@livepolls/ui-components/src/interfaces/livepoll-session-report.interface';
import { ILivePollSession } from '@livepolls/ui-components/src/interfaces/livepoll-session.interface';
import {
  transformLeaderboardToPager,
  transformTeamLeaderboardToPager,
} from '@livepolls/ui-components/src/utils/generate-leaderboard-row';
import { useState } from 'react';
import { ITeam } from '@livepolls/ui-components/src/interfaces/team.interface';
import { LpResponseCountAndTime } from '@livepolls/ui-components/src/components/response-count-and-time/LpResponseCountAndTime';
import { LpLivePollQuestionReportList } from './LpDashboardReportList';
import { ILiveFeedQuestionResult } from 'src/interfaces/livefeed-question-result.interface';
import styles from './LpDashboardReportForMobile.module.css';

interface Props {
  livePollSession: ILivePollSession;
  livePoll: { id: number; type: LivePollType };
  livePollSessionReport: ILivePollSessionReport;
  teams: ITeam[];
  fetchLiveFeedQuestionResult: (questionId: string) => ILiveFeedQuestionResult;
  downloadRawSessionReport?: (
    livePollSessionId: number,
  ) => Promise<void>;
  isReportsApp?: boolean;
  reportUrl?: string;
  text: {
    quiz: string;
    poll: string;
    duration: string;
    question: string;
    questions: string;
    participant: string;
    participants: string;
    noVotes: string;
    outOf: string;
    votes: string;
    initializing: string;
    noDataAvailable: string;
    default: string;
    popularity: string;
    accuracy: string;
    reverseOrder: string;
    sortBy: string;
    profileQuestion: string;
    teamRankings: string;
    individualView: string;
    teamsView: string;
    mentions: string;
    popularMentions: string;
    wordCloudText: string;
    topScorers: string;
    multipleChoice: string;
    swipeQuestionCorrectText: string;
    swipeQuestionIncorrectText: string;
    questionTypeText: string;
    easy: string;
    normal: string;
    hard: string;
    livefeedQuestionText: string;
    liveFeed: string;
    topVoted: string;
    bookmarked: string;
    comments: string;
    showing: string;
    showUpTo: string;
    of: string;
    to: string;
    invalidDropdownError: string;
    noComments: string;
    noVotesForComments: string;
    noBookmarkedComments: string;
    submissionTime: string;
    name: string;
    favorites: string;
    leaderboard: string;
    downloadCsv?: string;
  };
}
export const LpDashboardReportForMobile = ({
  livePollSession,
  livePoll,
  livePollSessionReport,
  fetchLiveFeedQuestionResult,
  downloadRawSessionReport,
  teams,
  reportUrl,
  isReportsApp,
  text,
}: Props) => {
  const [panelType, setPanelType] = useState<DashboardPanelType>(
    DashboardPanelType.LIVEPOLL_QUESTIONS,
  );
  const isProfileQuestionsActive =
    panelType === DashboardPanelType.PROFILE_QUESTIONS;

  const isLivePollQuestionsActive =
    panelType === DashboardPanelType.LIVEPOLL_QUESTIONS;

  const isLeaderboardActive = panelType === DashboardPanelType.LEADERBOARD;

  const isQuiz = livePoll.type === LivePollType.QUIZ;

  const {
    responseCount,
    questionReports,
    leaderboardRows,
    profileQuestionReports,
    teamLeaderboardRows,
  } = livePollSessionReport;

  const showDifficultyLevels =
    !!livePollSession.setting?.displayDifficultyLevels;
  const enableQuestionDifficulty =
    !!livePollSession.setting?.enableQuestionDifficulty;
  const isTeamsEnabled = !!livePollSession.setting?.teamSetting?.enabled;

  const handlePanelChange = (panelType: DashboardPanelType) => {
    setPanelType(panelType);
  };

  return (
    <>
      <div className={styles.left}>
        <div className={styles.leftWrapper}>
          <div className={styles.questionsContainer}>
            <LpResponseCountAndTime
              livePollSession={livePollSession}
              responseCount={responseCount}
              reportUrl={reportUrl}
              questionCount={questionReports.length}
              text={text}
              downloadRawSessionReport={downloadRawSessionReport}
              isReportsApp={isReportsApp}
            />

            <LpPanelContainer customContainerClass={styles.panelContainer}>
              <LpPanelHeading
                onPanelHeadClick={() =>
                  handlePanelChange(DashboardPanelType.LIVEPOLL_QUESTIONS)
                }
                isActive={isLivePollQuestionsActive}
              >
                <span className={styles.panelText}>Response summary</span>
              </LpPanelHeading>
              {livePollSession.setting?.enableProfileQuestions && (
                <LpPanelHeading
                  onPanelHeadClick={() =>
                    handlePanelChange(DashboardPanelType.PROFILE_QUESTIONS)
                  }
                  isActive={isProfileQuestionsActive}
                >
                  <span className={styles.panelText}>Profile Questions</span>
                </LpPanelHeading>
              )}

              {isQuiz && (
                <LpPanelHeading
                  onPanelHeadClick={() =>
                    handlePanelChange(DashboardPanelType.LEADERBOARD)
                  }
                  isActive={isLeaderboardActive}
                >
                  <span className={styles.panelText}>Leaderboard</span>
                </LpPanelHeading>
              )}
            </LpPanelContainer>
            {isLivePollQuestionsActive && (
              <LpLivePollQuestionReportList
                livePollId={livePollSession.livePollId}
                livePollSessionId={livePollSession.id}
                isQuiz={isQuiz}
                responseCount={responseCount}
                questionReports={questionReports}
                showDifficultyLevels={showDifficultyLevels}
                enableQuestionDifficulty={enableQuestionDifficulty}
                teamLeaderboardRows={teamLeaderboardRows}
                teams={teams}
                fetchLiveFeedQuestionResult={fetchLiveFeedQuestionResult}
                text={text}
              />
            )}
            {isProfileQuestionsActive && (
              <LpProfileQuestionReports
                questionReports={profileQuestionReports!}
                responseCount={responseCount}
                text={text}
              />
            )}
            {isLeaderboardActive && (
              <div className={styles.dashboardReport}>
                <LpLeaderboard
                  showSequenceNum={true}
                  pagewiseResults={transformLeaderboardToPager(
                    leaderboardRows,
                    {
                      leaderboard: text.leaderboard,
                    },
                  )}
                  text={{ noDataAvailable: text.noDataAvailable }}
                  isTeamsEnabled={isTeamsEnabled}
                  teamResults={transformTeamLeaderboardToPager(
                    teamLeaderboardRows || [],
                  )}
                  isResultScreeen={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
