import { LpMultipleChoiceReport } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpMultipleChoiceReport';
import { LpWordCloudReport } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpWordCloudReport';
import { LpSwipeQuestionReport } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpSwipeQuestionReport';
import { QuestionDifficultyLevel } from '@livepolls/ui-components/src/enums/question-difficulty-level.enum';
import { QuestionType } from '@livepolls/ui-components/src/enums/question-type';
import { ILiveFeedQuestionReport } from '@livepolls/ui-components/src/interfaces/livefeed-question-report';
import { IMultipleChoiceQuestionReport } from '@livepolls/ui-components/src/interfaces/multiple-choice-question-report';
import { TeamLeaderboardRow } from '@livepolls/ui-components/src/interfaces/team-leaderboard-row';
import { IWordCloudQuestionReport } from '@livepolls/ui-components/src/interfaces/word-cloud-report.interface';
import { CSSProperties } from 'react';
import { ISwipeQuestionReport } from '@livepolls/ui-components/src/interfaces/swipe-question-report.interface';
import { ITeam } from '@livepolls/ui-components/src/interfaces/team.interface';
import { LpLiveFeedReport } from '../livepoll-question-reports/components/LpLiveFeedReport';
import { ILiveFeedQuestionResult } from '@livepolls/ui-components/src/interfaces/livefeed-question-result.interface';

interface Props {
  livePollId: number;
  livePollSessionId: number;
  questionReports: (
    | IMultipleChoiceQuestionReport
    | ILiveFeedQuestionReport
    | IWordCloudQuestionReport
    | ISwipeQuestionReport
  )[];
  isQuiz: boolean;
  responseCount: number;
  showDifficultyLevels: boolean;
  enableQuestionDifficulty: boolean;
  teamLeaderboardRows?: TeamLeaderboardRow[];
  difficultyLevelIndicatorStyle?: CSSProperties;
  teams: ITeam[];
  fetchLiveFeedQuestionResult: (questionId: string) => ILiveFeedQuestionResult;
  text: {
    noVotes: string;
    outOf: string;
    votes: string;
    initializing: string;
    noDataAvailable: string;
    topScorers: string;
    default: string;
    popularity: string;
    accuracy: string;
    reverseOrder: string;
    sortBy: string;
    multipleChoice: string;
    teamRankings: string;
    individualView: string;
    teamsView: string;
    mentions: string;
    popularMentions: string;
    wordCloudText: string;
    swipeQuestionCorrectText: string;
    swipeQuestionIncorrectText: string;
    questionTypeText: string;
    easy: string;
    normal: string;
    hard: string;
    livefeedQuestionText: string;
    liveFeed: string;
    topVoted: string;
    bookmarked: string;
    comments: string;
    showing: string;
    showUpTo: string;
    of: string;
    to: string;
    invalidDropdownError: string;
    noComments: string;
    noVotesForComments: string;
    noBookmarkedComments: string;
    submissionTime: string;
    name: string;
    favorites: string;
  };
}

export const LpLivePollQuestionReportList = ({
  questionReports,
  isQuiz,
  responseCount,
  showDifficultyLevels,
  enableQuestionDifficulty,
  teamLeaderboardRows,
  difficultyLevelIndicatorStyle,
  teams,
  text,
  fetchLiveFeedQuestionResult,
}: Props) => {
  return (
    <>
      {questionReports.map(questionReport => {
        const { id, type } = questionReport;
        if (type === QuestionType.CHOICE) {
          const choiceQuestionReport =
            questionReport as IMultipleChoiceQuestionReport;
          const difficultyLevel =
            enableQuestionDifficulty &&
            choiceQuestionReport.difficultyLevel != null
              ? choiceQuestionReport.difficultyLevel
              : QuestionDifficultyLevel.NORMAL;
          return (
            <div key={id}>
              <LpMultipleChoiceReport
                isQuiz={isQuiz}
                responseCount={responseCount}
                questionReport={questionReport as IMultipleChoiceQuestionReport}
                teamLeaderboardRows={teamLeaderboardRows}
                text={text}
                showDifficultyLevels={showDifficultyLevels}
                difficultyLevel={difficultyLevel}
                difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
                teams={teams}
              />
            </div>
          );
        } else if (type === QuestionType.LIVE_FEED) {
          return (
            <LpLiveFeedReport
              key={id}
              liveFeedQuestionReport={questionReport as ILiveFeedQuestionReport}
              fetchLiveFeedQuestionResult={questionId =>
                fetchLiveFeedQuestionResult(questionId)
              }
              text={text}
            />
          );
        } else if (type === QuestionType.WORD_CLOUD) {
          return (
            <LpWordCloudReport
              key={id}
              wordCloudQuestionReport={
                questionReport as IWordCloudQuestionReport
              }
              text={text}
            />
          );
        } else if (type === QuestionType.SWIPE) {
          return (
            <LpSwipeQuestionReport
              key={id}
              questionReport={questionReport as ISwipeQuestionReport}
              isQuiz={isQuiz}
              teamLeaderboardRows={teamLeaderboardRows}
              text={text}
            />
          );
        } else {
          throw new Error('Incorrect question type');
        }
      })}
    </>
  );
};
