import { ILiveFeedQuestionReport } from '@livepolls/ui-components/src/interfaces/livefeed-question-report';
import { LpQuestionTitle } from '@livepolls/ui-components/src/components/question-title/LpQuestionTitle';
import { LpLiveFeedStats } from '@livepolls/ui-components/src/components/live-feed-question-report/LpLiveFeedStats';
import { useEffect, useState } from 'react';
import { ILiveFeedQuestionResultPaginationParams } from '@livepolls/ui-components/src/interfaces/live-feed-question-result-pagination-params.interface';
import { LiveFeedStatsDropdownEnum } from '@livepolls/ui-components/src/enums/live-feed-stats-dropdown.enum';
import {
  LIVE_FEED_PAGINATION_ITEMS_PER_PAGE,
  LIVE_FEED_PAGINATION_START_PAGE_INDEX,
} from '@livepolls/ui-components/src/constants/live-feed-pagination.constants';
import { IPaginateDropdown } from '@livepolls/ui-components/src/interfaces/paginate-dropdown.interface';
import { LiveFeedSvg } from '@livepolls/ui-components/src/components/svgImages/Livefeed.svg';
import { ILiveFeedQuestionResult } from '@livepolls/ui-components/src/interfaces/livefeed-question-result.interface';
import styles from './LpLiveFeedReport.module.css';
import { LiveFeedPinUnpin } from '../../../enums/livefeed-pin-unpin.enum';

interface Props {
  liveFeedQuestionReport: ILiveFeedQuestionReport;
  fetchLiveFeedQuestionResult: (questionId: string) => ILiveFeedQuestionResult;
  text: {
    livefeedQuestionText: string;
    liveFeed: string;
    topVoted: string;
    bookmarked: string;
    comments: string;
    showing: string;
    showUpTo: string;
    of: string;
    to: string;
    invalidDropdownError: string;
    noComments: string;
    noVotesForComments: string;
    noBookmarkedComments: string;
    submissionTime: string;
    name: string;
    favorites: string;
    votes: string;
  };
}

export const LpLiveFeedReport = ({
  liveFeedQuestionReport,
  fetchLiveFeedQuestionResult,
  text,
}: Props) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [dropDownText, setDropDownText] = useState<LiveFeedStatsDropdownEnum>(
    LiveFeedStatsDropdownEnum.LIVE_FEED,
  );
  const [questionResult, setQuestionResult] = useState<ILiveFeedQuestionResult>(
    {
      liveFeedResponses: [],
      responseCount: 0,
      responseCountForLiveFeedView: 0,
    },
  );

  const [paginationParams, setPaginationParams] =
    useState<ILiveFeedQuestionResultPaginationParams>({
      liveFeedView: LiveFeedStatsDropdownEnum.LIVE_FEED,
      pageIndex: LIVE_FEED_PAGINATION_START_PAGE_INDEX,
      perPage: LIVE_FEED_PAGINATION_ITEMS_PER_PAGE,
    });

  const totalItemsForPagination =
    questionResult?.responseCountForLiveFeedView || 0;
  const [liveFeeQuestionResponses, setLiveFeedQuestionResponses] = useState(
    questionResult?.liveFeedResponses || [],
  );

  const handleDropDownChange = (dropDownText: LiveFeedStatsDropdownEnum) => {
    setDropDownText(dropDownText);
  };

  useEffect(() => {
    const fetchResult = async () => {
      try {
        const result = await fetchLiveFeedQuestionResult(
          liveFeedQuestionReport.id,
        );
        setQuestionResult(result);
      } catch (err) {
        setError(err as any);
      } finally {
        setIsLoading(false);
      }
    };

    if (liveFeedQuestionReport?.id) {
      fetchResult();
    }
  }, [fetchLiveFeedQuestionResult, liveFeedQuestionReport]);

  const handlePageChange = (pager: IPaginateDropdown) => {
    setPaginationParams(current => {
      return { ...current, ...pager };
    });
  };

  useEffect(() => {
    if (!questionResult?.liveFeedResponses) {
      return;
    }

    const currLiveFeedQuestionResponses = questionResult?.liveFeedResponses;
    switch (dropDownText) {
      case LiveFeedStatsDropdownEnum.LIVE_FEED:
        setLiveFeedQuestionResponses(currLiveFeedQuestionResponses);
        break;

      case LiveFeedStatsDropdownEnum.TOP_VOTED:
        const sortedByUpvote = currLiveFeedQuestionResponses.filter(
          response => response.upvoteCount > 0,
        );
        sortedByUpvote.sort((a, b) => b.upvoteCount - a.upvoteCount);

        setLiveFeedQuestionResponses(sortedByUpvote);
        break;

      case LiveFeedStatsDropdownEnum.BOOKMARKED:
        const pinnedResponses = currLiveFeedQuestionResponses.filter(
          response => response.isPinned === LiveFeedPinUnpin.PIN,
        );
        setLiveFeedQuestionResponses(pinnedResponses);
        break;

      default:
        throw new Error('Invalid dropdown error');
    }
  }, [questionResult, dropDownText]);

  if (error) {
    return <div>Oops an error occurred (${(error as any)?.message}).</div>;
  }

  return (
    <div className={styles.container}>
      <LpQuestionTitle
        currentQuestionNumber={
          liveFeedQuestionReport.currentQuestionNumber || 0
        }
        title={liveFeedQuestionReport.title || ''}
        type={text.livefeedQuestionText}
        icon={<LiveFeedSvg />}
      />

      <div className={styles.liveFeedStatsContainer}>
        <LpLiveFeedStats
          dropDownText={dropDownText}
          isLoading={isLoading}
          onDropDownChange={handleDropDownChange}
          liveFeedQuestionResponses={liveFeeQuestionResponses}
          totalItems={totalItemsForPagination}
          pager={{
            pageIndex: paginationParams.pageIndex,
            perPage: paginationParams.perPage,
          }}
          onPageChange={handlePageChange}
          resultScreen={true}
          text={text}
        />
      </div>
    </div>
  );
};
