import { LpDashboardReport } from '@livepolls/ui-components/src/components/dashboard-report/LpDashboardReport';
import { LpDashboardReportForMobile } from '@livepolls/ui-components/src/components/dashboard-report/LpDashboardReportForMobile';
import { LpSpinner } from '@livepolls/ui-components/src/components/spinner/LpSpinner';
import { TABLET_SCREEN_BREAKPOINT } from '@livepolls/ui-components/src/constants/device-breakpoints.contants';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { ILivePollSessionReport } from '@livepolls/ui-components/src/interfaces/livepoll-session-report.interface';
import { ILivePollSession } from '@livepolls/ui-components/src/interfaces/livepoll-session.interface';
import { useScreenResize } from '@livepolls/ui-components/src/utils/getScreenSize.util';
import { useEffect, useState } from 'react';
import { getGlobals } from 'src/globals/globals';
import { getApi } from 'src/utils/api.util';
import styles from './DashboardReports.module.css';
import { LiveFeedStatsDropdownEnum } from '@livepolls/ui-components/src/enums/live-feed-stats-dropdown.enum';

interface Props {
  dashboardReportUid: string;
}

const getFetchLiveFeedCommentsUrl = (
  livePollSessionId: number,
  questionId: string,
): string => {
  return `/take/sharable-report/livepoll-session/${livePollSessionId}/question-result/${questionId}`;
};

const text = {
  quiz: 'Quiz',
  poll: 'Poll',
  duration: 'Duration',
  question: 'Question',
  questions: 'Questions',
  participant: 'Participant',
  participants: 'Participants',
  noVotes: 'No votes',
  outOf: 'out of',
  votes: 'Votes',
  initializing: 'Initializing',
  noDataAvailable: 'No data available',
  default: 'Default',
  popularity: 'Popularity',
  accuracy: 'Accuracy',
  reverseOrder: 'Reverse order',
  sortBy: 'Sort by',
  profileQuestion: 'Profile question',
  teamRankings: 'Team rankings',
  individualView: 'Individual view',
  teamsView: 'Teams view',
  mentions: 'Mentions',
  popularMentions: 'Popular mentions',
  wordCloudText: 'Word cloud',
  multipleChoice: 'Multiple choice',
  topScorers: 'Top scorers',
  swipeQuestionCorrectText: 'Swipe question correct text',
  swipeQuestionIncorrectText: 'Swipe question incorrect text',
  questionTypeText: 'This/That',
  easy: 'Easy',
  normal: 'Normal',
  hard: 'Hard',
  livefeedQuestionText: 'Livefeed',
  liveFeed: 'All responses',
  topVoted: 'Voted',
  bookmarked: 'Favorites',
  comments: 'Comments',
  showing: 'Showing',
  showUpTo: 'Show up to',
  of: 'of',
  to: 'to',
  invalidDropdownError: 'Invalid livefeed drop down text',
  noComments: 'No comments',
  noVotesForComments: 'No votes for comments',
  noBookmarkedComments: 'No favorite responses',
  submissionTime: 'Submission time',
  name: 'Name',
  favorites: 'Favorites',
  leaderboard: 'Leaderboard',
};

export const DashboardReports = ({ dashboardReportUid }: Props) => {
  const REPORT_URL = getGlobals()!.REPORT_URL;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { width } = useScreenResize();
  const [reportPayload, setReportPayload] = useState<{
    livePoll: { id: number; type: LivePollType };
    livePollSession: ILivePollSession;
    livePollSessionReport: ILivePollSessionReport;
  }>();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { livePoll, livePollSession, livePollSessionReport } =
          await getApi(
            `/take/sharable-report/livepoll-session/report/${dashboardReportUid}`,
          );

        setReportPayload({
          livePoll,
          livePollSession,
          livePollSessionReport,
        });
      } catch (err: any) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [dashboardReportUid]);

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <LpSpinner message="Loading Session Report..." />
      </div>
    );
  }

  if (error) {
    return <div>Oops an error occurred ({error?.message}).</div>;
  }

  if (!reportPayload?.livePoll) {
    return <div>Oops an error occurred (LivePoll not found).</div>;
  }

  if (!reportPayload.livePollSession) {
    return <div>Oops an error occurred (LivePollSession not found).</div>;
  }

  if (!reportPayload.livePollSessionReport) {
    return <div>Oops an error occurred (LivePollSessionReport not found).</div>;
  }

  const { livePoll, livePollSession, livePollSessionReport } = reportPayload;
  const teams = livePollSession.setting?.teamSetting?.teams || [];
  const isTabletView = width <= TABLET_SCREEN_BREAKPOINT;
  const reportUrl = getReportUrl(
    REPORT_URL,
    livePollSession.sharableReportDashboardUid,
  );

  const fetchLiveFeedQuestionResult = async (questinId: string) => {
    const livePollSessionId = livePollSession.id;
    return await getApi(
      getFetchLiveFeedCommentsUrl(livePollSessionId, questinId),
      {
        params: {
          liveFeedView: LiveFeedStatsDropdownEnum.LIVE_FEED,
        },
      },
    );
  };

  if (isTabletView) {
    return (
      <LpDashboardReportForMobile
        livePollSession={livePollSession}
        livePoll={livePoll}
        livePollSessionReport={livePollSessionReport}
        reportUrl={getReportUrl(livePollSession.sharableReportDashboardUid)}
        teams={teams}
        fetchLiveFeedQuestionResult={questionId =>
          fetchLiveFeedQuestionResult(questionId)
        }
        isReportsApp={true}
        text={text}
      />
    );
  }

  return (
    <LpDashboardReport
      livePoll={livePoll}
      livePollSession={livePollSession}
      livePollSessionReport={livePollSessionReport}
      reportUrl={reportUrl}
      fetchLiveFeedQuestionResult={fetchLiveFeedQuestionResult}
      text={text}
      isReportsApp={true}
    />
  );
};

const getReportUrl = (reportUrl: string, reportUid?: string) => {
  if (reportUid != null) {
    return `${reportUrl}/${reportUid}`;
  }
};
