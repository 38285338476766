import { LpSpinner } from '../../../components/spinner/LpSpinner';
import { ChoiceQuestionAnswerStat } from '../../../interfaces/answer-stats.interface';
import { useCallback, useEffect, useRef, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SortOrder } from '../../../enums/sort-order.enum';
import { getAnswerText } from '../../../utils/getAnswerText.util';
import styles from './LpIndividualViewQuestionResultChartForChoice.module.css';
import { useScreenResize } from '../../../utils/getScreenSize.util';
import { getLightColor } from '../../../utils/color.util';
import { PIXEL_CONSTANTS } from '../../../constants/pixel-constants';
import { WuTooltip } from '@npm-questionpro/wick-ui-lib';
import { isDesktopDevice } from '../../../utils/isDesktopDevice.util';

const MARGIN_FOR_ANSWER = 8;
const DEFAULT_ANSWER_TEXT_COLOR = '#545E6B';
const NOT_ANSWERED_BAR_COLOR = '#9B9B9B';

interface Props {
  isQuiz: boolean;
  answerStats: ChoiceQuestionAnswerStat[];
  order: SortOrder;
  mainColor?: string;
  showDefaultChart: boolean;
  text: {
    noVotes: string;
    outOf: string;
    votes: string;
    noDataAvailable: string;
    initializing: string;
    sortBy: string;
    default: string;
    popularity: string;
    accuracy: string;
    reverseOrder: string;
  };
}

export const LpIndividualViewQuestionResultChartForChoice = ({
  answerStats,
  isQuiz,
  text,
  order,
  showDefaultChart,
  mainColor,
}: Props) => {
  const [answerWidth, setAnswerWidth] = useState<number>(0);
  const [barWidth, setBarWidth] = useState<number>(0);

  const [highlightBarColor, setHighlightBarColor] = useState<string>('');
  const [barColor, setBarColor] = useState<string>('');

  const { width } = useScreenResize();
  const isDesktopView = isDesktopDevice(width);

  const [upatedAnswerStats, setUpdateAnswerStats] =
    useState<ChoiceQuestionAnswerStat[]>(answerStats);

  const totalResponses = answerStats.reduce(
    (accumulator, currAnswer) => accumulator + currAnswer.count,
    0,
  );
  const answerTextRef = useRef<HTMLDivElement | null>(null);
  const barRef = useRef<HTMLDivElement | null>(null);

  const maxTopVotedAnswerCount = Math.max(
    ...answerStats.map(obj => obj.count).filter(count => count > 0),
  );
  const topVotedAnswerIds = answerStats
    .filter(stat => stat.count === maxTopVotedAnswerCount)
    .map(answer => answer.id);

  useEffect(() => {
    const updateColors = () => {
      const computedMainColor = mainColor
        ? mainColor
        : document.documentElement.style.getPropertyValue('--main');
      const color = computedMainColor || '#1b3380';
      setHighlightBarColor(color);
      setBarColor(getLightColor(color));
    };

    updateColors();
  }, [mainColor]);

  const adjustHeight = useCallback(() => {
    const answerTextDiv = answerTextRef.current;
    const barDiv = barRef.current;

    if (!answerTextDiv || !barDiv) {
      return;
    }

    const answerTextDivRect = answerTextDiv.getBoundingClientRect();
    const barDivRect = barDiv.getBoundingClientRect();

    if (answerTextDivRect.width) {
      setAnswerWidth(answerTextDivRect.width + MARGIN_FOR_ANSWER);
    }

    if (barDivRect.width) {
      setBarWidth(barDivRect.width);
    }
  }, [answerTextRef, barRef, setAnswerWidth, setBarWidth]);

  useEffect(() => {
    adjustHeight();
  }, [adjustHeight, answerTextRef, barRef]);

  useEffect(() => {
    let sortedAnswerStats = [...answerStats];
    if (!showDefaultChart) {
      // Sort by count in desc order
      sortedAnswerStats.sort((a, b) => b.count - a.count);
    }
    // Reverse the order if the sort order is ASC
    if (order === SortOrder.ASC) {
      sortedAnswerStats.reverse();
    }
    setUpdateAnswerStats(sortedAnswerStats);
  }, [answerStats, order, showDefaultChart]);

  const isTopVotedAnswer = (answerId: string) => {
    return topVotedAnswerIds.includes(answerId);
  };

  const isCorrectOrTopVotedAnswer = (
    isQuiz: boolean,
    answerId: string,
    isCorrect?: boolean,
  ) => {
    if (answerId === 'NA') {
      return false;
    } else if (isQuiz) {
      return !!isCorrect;
    } else {
      return isTopVotedAnswer(answerId);
    }
  };

  const getBarColor = (id: string, isCorrectAnswer?: boolean) => {
    if (id === 'NA') {
      return NOT_ANSWERED_BAR_COLOR;
    }
    const isCorrect = isCorrectOrTopVotedAnswer(isQuiz, id, isCorrectAnswer);
    if (isCorrect) {
      return highlightBarColor;
    }

    return barColor;
  };

  const createTooltipText = (count: number, totalResponses: number) => {
    return `${count} ${
      text.outOf
    } ${totalResponses} ${text.votes.toLowerCase()}`;
  };

  if (!answerStats) {
    return (
      <div className={styles.dataNotAvailableContainer}>
        <LpSpinner />
      </div>
    );
  }

  if (answerStats.length === 0) {
    return (
      <div className={styles.dataNotAvailableContainer}>
        {text.noDataAvailable}
      </div>
    );
  }
  const displayBottomAndCenterLine = isDesktopView && answerWidth > 0;
  return (
    <div className={styles.container}>
      {upatedAnswerStats.map((answerStat, index) => {
        return (
          <div key={answerStat.id} className={styles.answerRow}>
            <div
              className={styles.answerTextRow}
              ref={index === 0 ? answerTextRef : null}
            >
              {answerStat.image?.url && (
                <img
                  src={answerStat.image.url}
                  alt="answer"
                  className={styles.answerImage}
                  width="60px"
                  height="40px"
                />
              )}
              <div
                style={getTextStyles(
                  answerStat.text || 'Untitled',
                  highlightBarColor,
                  isDesktopView,
                  isCorrectOrTopVotedAnswer(
                    isQuiz,
                    answerStat.id,
                    answerStat.isCorrect,
                  ),
                )}
                className={styles.answerText}
                data-testid="answerText"
              >
                {getAnswerText(answerStat)}
              </div>
            </div>
            <WuTooltip
              content={createTooltipText(answerStat.count, totalResponses)}
              position="left"
            >
              <div className={styles.barRow} ref={index === 0 ? barRef : null}>
                <div
                  className={styles.percentage}
                  style={{
                    width: `${getPercentage(
                      answerStat.count,
                      totalResponses,
                    )}%`,
                    backgroundColor: getBarColor(
                      answerStat.id,
                      answerStat.isCorrect,
                    ),
                  }}
                >
                  <div
                    className={styles.percentageText}
                    style={
                      isCorrectOrTopVotedAnswer(
                        isQuiz,
                        answerStat.id,
                        answerStat.isCorrect,
                      )
                        ? { color: highlightBarColor, fontWeight: 500 }
                        : undefined
                    }
                  >
                    {Math.floor(
                      getPercentage(answerStat.count, totalResponses),
                    )}
                    %
                    {isCorrectAnswer(answerStat.isCorrect, isQuiz) && (
                      <span className={styles.correctIcon}>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          style={
                            isDesktopView
                              ? { width: '20px', height: '20px' }
                              : { width: '16px', height: '16px' }
                          }
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </WuTooltip>
          </div>
        );
      })}
      {displayBottomAndCenterLine && (
        <>
          <div
            style={{ left: `${answerWidth}px` }}
            className={styles.centerLine}
          ></div>
          <div
            style={{ left: `${answerWidth}px`, width: `${barWidth}px` }}
            className={styles.bottomLine}
          ></div>
        </>
      )}
    </div>
  );
};

const getTextStyles = (
  title: string,
  highlightBarColor: string,
  isDesktopView: boolean,
  isHighlighted: boolean,
) => {
  const { fontSize, lineHeight } = getFontSize(title, isDesktopView);
  return {
    fontSize,
    lineHeight,
    color: isHighlighted ? highlightBarColor : DEFAULT_ANSWER_TEXT_COLOR,
    fontWeight: isHighlighted ? 500 : 400,
  };
};

const getFontSize = (title: string, isDesktopView: boolean) => {
  const titleLength = title?.length || 0;

  if (isDesktopView) {
    const smallText = titleLength > 23 && titleLength <= 52;
    const mediumText = titleLength > 52 && titleLength <= 90;
    const largeText = titleLength > 90;
    if (smallText) {
      return {
        fontSize: PIXEL_CONSTANTS.sixteen,
        lineHeight: PIXEL_CONSTANTS.twentyfour,
      };
    } else if (mediumText) {
      return {
        fontSize: PIXEL_CONSTANTS.fourteen,
        lineHeight: PIXEL_CONSTANTS.twentyone,
      };
    } else if (largeText) {
      return {
        fontSize: PIXEL_CONSTANTS.twelve,
        lineHeight: PIXEL_CONSTANTS.eighteen,
      };
    }
    return {
      fontSize: PIXEL_CONSTANTS.eighteen,
      lineHeight: PIXEL_CONSTANTS.eighteen,
    };
  } else {
    const smallText = titleLength > 21 && titleLength <= 48;
    const largeText = titleLength > 48;
    if (smallText) {
      return {
        fontSize: PIXEL_CONSTANTS.fourteen,
        lineHeight: PIXEL_CONSTANTS.twentyone,
      };
    } else if (largeText) {
      return {
        fontSize: PIXEL_CONSTANTS.twelve,
        lineHeight: PIXEL_CONSTANTS.eighteen,
      };
    }
    return {
      fontSize: PIXEL_CONSTANTS.sixteen,
      lineHeight: PIXEL_CONSTANTS.sixteen,
    };
  }
};

const getPercentage = (count: number, totalResponses: number) => {
  if (count === 0 || totalResponses === 0) {
    return 0;
  }
  const percentage = (count * 100) / totalResponses;
  const lessThanOnePercent = percentage >= 0.5 && percentage < 1;
  if (lessThanOnePercent) {
    return 0;
  }
  return Math.floor(percentage);
};

const isCorrectAnswer = (isCorrect: boolean | undefined, isQuiz: boolean) => {
  return !!(isCorrect && isQuiz);
};
