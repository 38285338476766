import { useState } from 'react';
import { LpSpinner } from '../../../components/spinner/LpSpinner';
import { SortOrder } from '../../../enums/sort-order.enum';
import { ChoiceQuestionAnswerStat } from '../../../interfaces/answer-stats.interface';
import { LpIndividualViewQuestionResultChartForChoice } from './LpIndividualViewQuestionResultChartForChoice';
import styles from './LpQuestionResultChartForChoice.module.css';
import { LpChoiceQuestionResultChartHeader } from './LpChoiceQuestionResuiltChartHeader';
import { TeamResponse } from '../../../interfaces/team-response.interface';
import { ITeam } from '../../../interfaces/team.interface';
import { LpQuestionResultChartForTeams } from './LpQuestionResultChartForTeamsForChoice';
import { ToggleSwitchSideEnum } from '../../../enums/toggle-switch-side.enum';

export enum IndividualOrTeamsView {
  Individual,
  Teams,
}

interface Props {
  isQuiz: boolean;
  answerStats: ChoiceQuestionAnswerStat[];
  mainColor?: string;
  showTeamsChart?: boolean;
  responsesByTeam?: TeamResponse[];
  teams: ITeam[];
  text: {
    noVotes: string;
    outOf: string;
    votes: string;
    noDataAvailable: string;
    initializing: string;
    sortBy: string;
    default: string;
    popularity: string;
    accuracy: string;
    reverseOrder: string;
    teamRankings: string;
    individualView: string;
    teamsView: string;
  };
}

export const LpQuestionResultChartForChoice = ({
  answerStats,
  isQuiz,
  text,
  responsesByTeam,
  teams,
  showTeamsChart,
  mainColor,
}: Props) => {
  const [view, setView] = useState<IndividualOrTeamsView>(
    IndividualOrTeamsView.Individual,
  );
  const [order, setOrder] = useState<SortOrder>(SortOrder.DESC);
  const [showDefaultChart, setShowDefaultChart] = useState<boolean>(true);

  if (!answerStats) {
    return (
      <div className={styles.dataNotAvailableContainer}>
        <LpSpinner />
      </div>
    );
  }

  if (answerStats.length === 0) {
    return (
      <div className={styles.dataNotAvailableContainer}>
        {text.noDataAvailable}
      </div>
    );
  }

  const toggleView = (view: ToggleSwitchSideEnum) => {
    if (view === ToggleSwitchSideEnum.RIGHT) {
      setView(IndividualOrTeamsView.Teams);
      return;
    }
    setView(IndividualOrTeamsView.Individual);
  };

  const changeOrder = (order: SortOrder) => {
    if (order === SortOrder.ASC) {
      setOrder(SortOrder.DESC);
    } else {
      setOrder(SortOrder.ASC);
    }
  };

  const onDropDownChange = (isDefault: boolean) => {
    setShowDefaultChart(isDefault);
  };

  const getDropownText = () => {
    if (showDefaultChart) return text.default;
    if (isQuiz) return text.accuracy;
    return text.popularity;
  };

  return (
    <div className={styles.chartContainer} data-testid="questionResultChart">
      <div className={styles.sortByContainer}>
        <LpChoiceQuestionResultChartHeader
          isQuiz={isQuiz}
          isTeamsEnabled={!!showTeamsChart}
          onChangeView={toggleView}
          onChangeOrder={changeOrder}
          onDropDownChange={onDropDownChange}
          order={order}
          view={
            IndividualOrTeamsView.Teams === view
              ? ToggleSwitchSideEnum.RIGHT
              : ToggleSwitchSideEnum.LEFT
          }
          dropownText={getDropownText()}
          text={text}
        />
      </div>
      {view === IndividualOrTeamsView.Individual && (
        <LpIndividualViewQuestionResultChartForChoice
          isQuiz={isQuiz}
          answerStats={answerStats}
          text={text}
          order={order}
          showDefaultChart={showDefaultChart}
          mainColor={mainColor}
        />
      )}
      {view === IndividualOrTeamsView.Teams && (
        <LpQuestionResultChartForTeams
          responsesByTeam={responsesByTeam || []}
          teams={teams}
          answerStats={answerStats}
          text={text}
          order={order}
          showDefaultChart={showDefaultChart}
        />
      )}
    </div>
  );
};
