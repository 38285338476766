import { useState } from 'react';
import { LpLeaderboard } from '@livepolls/ui-components/src/components/leaderboard/LpLeaderboard';
import { LpPanelContainer } from '@livepolls/ui-components/src/components/panel/LpPanelContainer';
import { LpPanelHeading } from '@livepolls/ui-components/src/components/panel/LpPanelHeading';
import { DashboardPanelType } from '@livepolls/ui-components/src/enums/dashboard-panel-type.enum';
import { LivePollType } from '@livepolls/ui-components/src/enums/livepoll-type.enum';
import { LpResponseCountAndTime } from '@livepolls/ui-components/src/components/response-count-and-time/LpResponseCountAndTime';
import { LpProfileQuestionReports } from '@livepolls/ui-components/src/components/livepoll-question-reports/components/LpProfileQuestionReports';
import {
  transformLeaderboardToPager,
  transformTeamLeaderboardToPager,
} from '@livepolls/ui-components/src/utils/generate-leaderboard-row';
import { LpLivePollQuestionReportList } from './LpDashboardReportList';
import { ILivePollSession } from 'src/interfaces/livepoll-session.interface';
import { ILivePollSessionReport } from 'src/interfaces/livepoll-session-report.interface';
import { ILivePoll } from 'src/interfaces/livepoll.interface';
import { ILiveFeedQuestionResult } from 'src/interfaces/livefeed-question-result.interface';
import styles from './LpDashboardReport.module.css';

interface Props {
  livePoll: ILivePoll;
  livePollSession: ILivePollSession;
  livePollSessionReport: ILivePollSessionReport;
  fetchLiveFeedQuestionResult: (questionId: string) => ILiveFeedQuestionResult;
  reportUrl?: string;
  onBackButtonClick?(): void;
  downloadRawSessionReport?: (
    livePollSession: number,
  ) => Promise<void>;
  isReportsApp?: boolean;
  text: {
    quiz: string;
    poll: string;
    duration: string;
    question: string;
    questions: string;
    participant: string;
    participants: string;
    noVotes: string;
    outOf: string;
    votes: string;
    initializing: string;
    noDataAvailable: string;
    default: string;
    popularity: string;
    accuracy: string;
    reverseOrder: string;
    sortBy: string;
    profileQuestion: string;
    teamRankings: string;
    individualView: string;
    teamsView: string;
    mentions: string;
    popularMentions: string;
    wordCloudText: string;
    topScorers: string;
    multipleChoice: string;
    swipeQuestionCorrectText: string;
    swipeQuestionIncorrectText: string;
    questionTypeText: string;
    easy: string;
    normal: string;
    hard: string;
    livefeedQuestionText: string;
    liveFeed: string;
    topVoted: string;
    bookmarked: string;
    comments: string;
    showing: string;
    showUpTo: string;
    of: string;
    to: string;
    invalidDropdownError: string;
    noComments: string;
    noVotesForComments: string;
    noBookmarkedComments: string;
    submissionTime: string;
    name: string;
    favorites: string;
    leaderboard: string;
    downloadCsv?: string;
  };
}

export const LpDashboardReport = ({
  livePoll,
  livePollSession,
  livePollSessionReport,
  fetchLiveFeedQuestionResult,
  downloadRawSessionReport,
  reportUrl,
  text,
  onBackButtonClick,
  isReportsApp,
}: Props) => {
  const [panelType, setPanelType] = useState<DashboardPanelType>(
    DashboardPanelType.LIVEPOLL_QUESTIONS,
  );

  const isQuiz = livePoll.type === LivePollType.QUIZ;
  const {
    responseCount,
    questionReports,
    leaderboardRows,
    profileQuestionReports,
    teamLeaderboardRows,
  } = livePollSessionReport;

  const displayRightWidget = isQuiz;
  const handelPanelChange = (panelType: DashboardPanelType) => {
    setPanelType(panelType);
  };
  const isProfileQuestionsActive =
    panelType === DashboardPanelType.PROFILE_QUESTIONS;
  const isLivePollQuestionsActive =
    panelType === DashboardPanelType.LIVEPOLL_QUESTIONS;

  const showDifficultyLevels =
    !!livePollSession.setting?.displayDifficultyLevels;
  const enableQuestionDifficulty =
    !!livePollSession.setting?.enableQuestionDifficulty;
  const isTeamsEnabled = !!livePollSession.setting?.teamSetting?.enabled;
  const teams = livePollSession.setting?.teamSetting?.teams || [];

  const difficultyLevelIndicatorStyle: React.CSSProperties = {
    fontSize: '14px',
  };

  return (
    <div className={styles.left}>
      <div className={styles.leftWrapper}>
        <div
          className={
            isQuiz
              ? styles.questionsContainer
              : styles.questionsContainerForPoll
          }
        >
          <LpResponseCountAndTime
            livePollSession={livePollSession}
            responseCount={responseCount}
            onBackButtonClick={() => onBackButtonClick && onBackButtonClick()}
            reportUrl={reportUrl}
            questionCount={questionReports.length}
            text={text}
            downloadRawSessionReport={downloadRawSessionReport}
            isReportsApp={isReportsApp}
          />
          <div className={styles.mainWrapper}>
            <div className={styles.innerContainer}>
              {!livePollSession.setting?.enableProfileQuestions && (
                <div className={styles.responseSummaryText}>
                  Response summary
                </div>
              )}
              {livePollSession.setting?.enableProfileQuestions && (
                <LpPanelContainer customContainerClass={styles.panelContainer}>
                  <LpPanelHeading
                    onPanelHeadClick={() =>
                      handelPanelChange(DashboardPanelType.LIVEPOLL_QUESTIONS)
                    }
                    isActive={isLivePollQuestionsActive}
                  >
                    <span className={styles.panelText}>Response summary</span>
                  </LpPanelHeading>

                  <LpPanelHeading
                    onPanelHeadClick={() =>
                      handelPanelChange(DashboardPanelType.PROFILE_QUESTIONS)
                    }
                    isActive={isProfileQuestionsActive}
                  >
                    <span className={styles.panelText}>Profile Questions</span>
                  </LpPanelHeading>
                </LpPanelContainer>
              )}

              {isLivePollQuestionsActive && (
                <LpLivePollQuestionReportList
                  livePollId={livePollSession.livePollId}
                  livePollSessionId={livePollSession.id}
                  isQuiz={isQuiz}
                  responseCount={responseCount}
                  questionReports={questionReports}
                  showDifficultyLevels={showDifficultyLevels}
                  enableQuestionDifficulty={enableQuestionDifficulty}
                  teamLeaderboardRows={teamLeaderboardRows}
                  teams={teams}
                  difficultyLevelIndicatorStyle={difficultyLevelIndicatorStyle}
                  fetchLiveFeedQuestionResult={questionId =>
                    fetchLiveFeedQuestionResult(questionId)
                  }
                  text={text}
                />
              )}
              {isProfileQuestionsActive && (
                <LpProfileQuestionReports
                  questionReports={profileQuestionReports!}
                  responseCount={responseCount}
                  text={text}
                />
              )}
            </div>
            {displayRightWidget && (
              <div className={styles.dashboardReport}>
                <LpLeaderboard
                  showSequenceNum={true}
                  pagewiseResults={transformLeaderboardToPager(
                    leaderboardRows,
                    {
                      leaderboard: text.leaderboard,
                    },
                  )}
                  text={text}
                  isTeamsEnabled={isTeamsEnabled}
                  teamResults={transformTeamLeaderboardToPager(
                    teamLeaderboardRows || [],
                  )}
                  isResultScreeen={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
